import { InfoBoxesCardConfigType, InfoBoxesCardDataType, InfoBoxesCardType } from "./types";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";

export const COST_VARIANT = "COST_VARIANT";
export const SECURITY_VARIANT = "SECURITY_VARIANT";
export const RELIABILITY_VARIANT = "RELIABILITY_VARIANT";
export const OPERATIONAL_EXCELLENCE_VARIANT = "OPERATIONAL_EXCELLENCE_VARIANT";
export const PERFORMANCE_VARIANT = "PERFORMANCE_VARIANT";

export const INFO_BOXES_VARIANTS: Array<InfoBoxesCardType> = [
  COST_VARIANT,
  SECURITY_VARIANT,
  RELIABILITY_VARIANT,
  OPERATIONAL_EXCELLENCE_VARIANT,
  PERFORMANCE_VARIANT,
];

export const InfoBoxesCardData: InfoBoxesCardDataType = {
  [COST_VARIANT]: ["size"],
  [SECURITY_VARIANT]: ["successRate"],
  [RELIABILITY_VARIANT]: ["count"],
  [OPERATIONAL_EXCELLENCE_VARIANT]: ["totalJobs"],
  [PERFORMANCE_VARIANT]: ["totalJobs"],
};

export const InfoBoxesCardsConfig: Record<InfoBoxesCardType, InfoBoxesCardConfigType> = {
  [COST_VARIANT]: {
    selector: dashboardSelectors.getCostScoreSummary,
    thunk: dashboardThunks.getAdvisorScoreInfo,
    labelPhrase: "infoBoxesBlock.costLabel",
    subLabelPhrase: "infoBoxesBlock.costSubLabel",
    style: "costVariant",
    progressBarBgColor: "#44d9fc",
  },
  [SECURITY_VARIANT]: {
    selector: dashboardSelectors.getSecurityScoreSummary,
    thunk: dashboardThunks.getAdvisorScoreInfo,
    labelPhrase: "infoBoxesBlock.securityLabel",
    subLabelPhrase: "infoBoxesBlock.securitySubLabel",
    style: "securityVariant",
    progressBarBgColor: "#ff5a5a",
  },
  [RELIABILITY_VARIANT]: {
    selector: dashboardSelectors.getReliabilityScoreSummary,
    thunk: dashboardThunks.getAdvisorScoreInfo,
    labelPhrase: "infoBoxesBlock.reliabilityLabel",
    subLabelPhrase: "infoBoxesBlock.reliabilitySubLabel",
    style: "reliabilityVariant",
    progressBarBgColor: "#b4b4b4",
  },
  [OPERATIONAL_EXCELLENCE_VARIANT]: {
    selector: dashboardSelectors.getOperationalExcellenceScoreSummary,
    thunk: dashboardThunks.getAdvisorScoreInfo,
    labelPhrase: "infoBoxesBlock.operationalExcellenceLabel",
    subLabelPhrase: "infoBoxesBlock.operationalExcellenceSubLabel",
    style: "operationalExcellenceVariant",
    progressBarBgColor: "#4ef09d",
  },
  [PERFORMANCE_VARIANT]: {
    selector: dashboardSelectors.getPerformanceScoreSummary,
    thunk: dashboardThunks.getAdvisorScoreInfo,
    labelPhrase: "infoBoxesBlock.performanceLabel",
    subLabelPhrase: "infoBoxesBlock.performanceSubLabel",
    style: "performanceVariant",
    progressBarBgColor: "#e59a7f",
  },
};
