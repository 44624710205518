import { AppThunk } from "store";
import * as dashboardApi from "api/dashboard.api";
import * as dashboardActions from "./dashboard.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "../notification/notification.actions";
import { ERROR_CANCELED_CODE, parseApiErrorMessage } from "utils/helpers";
import { CURRENCY_CODE } from "types/dashboardApi.types";

export const getSubscriptionsInfo =
  (selectedContractId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      const { data } = await dashboardApi.getSubscriptions(selectedContractId, signal);
      dispatch(dashboardActions.setSubscriptionsList(data.subscriptions));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "SUBSCRIPTIONS REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:publicCloudSubscriptionsRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
      }
    }
  };

export const getAdvisorScoreInfo =
  (selectedContractId: string, subscriptionIds: string[], signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.advisorScoreRequestAC());
      const { data } = await dashboardApi.getAdvisorScore(selectedContractId, subscriptionIds, signal);
      dispatch(dashboardActions.advisorScoreRequestSuccessAC(data));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "ADVISOR SCORE STATUS REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:publicCloudAdvisorScoreRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.advisorScoreRequestFailureAC());
      }
    }
  };

export const getCostLastMonthInfo =
  (
    selectedContractId: string,
    subscriptionIds: string[],
    currencyCode: CURRENCY_CODE,
    signal?: AbortSignal
  ): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dashboardActions.costLastMonthRequestAC());
      const { data } = await dashboardApi.getCostLastMonth(selectedContractId, subscriptionIds, currencyCode, signal);
      dispatch(dashboardActions.costLastMonthRequestSuccessAC(data));
    } catch (apiErrorMessage: any) {
      if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
        console.error(apiErrorMessage, "COST LAST MONTH REQUEST");
        dispatch(
          notificationErrorAC({
            message: i18n.t("notification:publicCloudCostLastMonthRequestFailure"),
            description: parseApiErrorMessage(apiErrorMessage),
          })
        );
        dispatch(dashboardActions.costLastMonthRequestFailureAC());
      }
    }
  };
