import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import { dashboardActions, dashboardSelectors, dashboardThunks } from "store/dashboard";
import useFetchCostLastMonthData from "hooks/useFetchCostLastMonthData";
import { Card, Dropdown, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { CURRENCY_CODE } from "types/dashboardApi.types";
import { localStorageCurrencyKey } from "store/dashboard/dashboard.reducer";
import CostLastMonthTable from "./CostLastMonthTable";
import { getPreviousMonth } from "utils/time";

import styles from "./styles.module.css";

const currencyOptions = [
  { label: "EUR", value: CURRENCY_CODE.EUR },
  { label: "SEK", value: CURRENCY_CODE.SEK },
  { label: "NOK", value: CURRENCY_CODE.NOK },
  { label: "DKK", value: CURRENCY_CODE.DKK },
];

const currencySigns = {
  [CURRENCY_CODE.EUR]: "€",
  [CURRENCY_CODE.SEK]: "kr",
  [CURRENCY_CODE.NOK]: "kr",
  [CURRENCY_CODE.DKK]: "kr",
};

const CostLastMonthBlock: React.FC = () => {
  useFetchCostLastMonthData();
  const { t } = useTranslation("publicCloudDashboard");
  const { totalCost, currency, requestStatus } = useSelector(dashboardSelectors.getCostLastMonth);
  const dispatch = useDispatch();

  const handleCurrencyChange = useCallback(
    (value: string) => {
      dispatch(dashboardActions.setCostLastMonthCurrencyAC(value as CURRENCY_CODE));
      localStorage.setItem(localStorageCurrencyKey, value);
    },
    [dispatch]
  );

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);
  const reloadData = useCallback(() => {
    if (selectedContractId && selectedSubscriptionIds.length) {
      thunkDispatch(dashboardThunks.getCostLastMonthInfo(selectedContractId, selectedSubscriptionIds, currency));
    }
  }, [currency, selectedContractId, selectedSubscriptionIds, thunkDispatch]);

  const previousMonth = useMemo(() => getPreviousMonth(), []);

  return (
    <Card className={styles.costLastMonthCard} data-testid="costLastMonthCard">
      <div className={styles.currencyDropdownBlock}>
        <span>{t("currencyDropdownLabel")}</span>
        <Dropdown
          data-testid="currencyDropdown"
          onChange={handleCurrencyChange}
          value={currency}
          options={currencyOptions}
          className={styles.currencyDropdown}
        />
      </div>
      <div className={styles.costLastMonthContent}>
        {(requestStatus === RequestStatus.UNCALLED || requestStatus === RequestStatus.PENDING) && <Spinner show />}
        {requestStatus === RequestStatus.SUCCESS && (
          <>
            <div className={styles.totalCostLastMonth}>
              <span className={styles.totalCostLastMonthLabel}>
                {`${t("totalCostLastMonthLabel")} ${previousMonth}`}
              </span>
              <span className={styles.totalCostLastMonthValue}>
                {currencySigns[currency]}
                {String(totalCost).replace(".", ",")}
              </span>
            </div>
            <CostLastMonthTable currencySign={currencySigns[currency]} />
          </>
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      </div>
    </Card>
  );
};

export default CostLastMonthBlock;
