// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicCloud_styles_costLastMonthCard__D8NNk {
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgba(0 0 0 / 19%);
}

.publicCloud_styles_costLastMonthContent__f3Z2j {
  position: relative;
  display: flex;
  min-height: 300px;
  flex-direction: column;
  gap: 24px;
}

.publicCloud_styles_currencyDropdownBlock__g6gZV {
  display: flex;
  width: 250px;
  flex-direction: column;
  padding: 16px 16px 24px;
}

.publicCloud_styles_currencyDropdown__F3JtW {
  background-color: var(--color-grey-02) !important;
}

.publicCloud_styles_currencyDropdownBlock__g6gZV span,
.publicCloud_styles_totalCostLastMonthLabel__NvbrS {
  margin-bottom: 4px;
  font-size: 14px;
}

.publicCloud_styles_totalCostLastMonth__nGEeQ {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.publicCloud_styles_totalCostLastMonthValue__80dTk {
  font-size: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/components/CostLastMonthBlock/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,yCAAyC;AAC3C;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,iDAAiD;AACnD;;AAEA;;EAEE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".costLastMonthCard {\n  overflow: hidden;\n  box-shadow: 0 1px 1px 0 rgba(0 0 0 / 19%);\n}\n\n.costLastMonthContent {\n  position: relative;\n  display: flex;\n  min-height: 300px;\n  flex-direction: column;\n  gap: 24px;\n}\n\n.currencyDropdownBlock {\n  display: flex;\n  width: 250px;\n  flex-direction: column;\n  padding: 16px 16px 24px;\n}\n\n.currencyDropdown {\n  background-color: var(--color-grey-02) !important;\n}\n\n.currencyDropdownBlock span,\n.totalCostLastMonthLabel {\n  margin-bottom: 4px;\n  font-size: 14px;\n}\n\n.totalCostLastMonth {\n  display: flex;\n  flex-direction: column;\n  padding: 0 16px;\n}\n\n.totalCostLastMonthValue {\n  font-size: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"costLastMonthCard": `publicCloud_styles_costLastMonthCard__D8NNk`,
	"costLastMonthContent": `publicCloud_styles_costLastMonthContent__f3Z2j`,
	"currencyDropdownBlock": `publicCloud_styles_currencyDropdownBlock__g6gZV`,
	"currencyDropdown": `publicCloud_styles_currencyDropdown__F3JtW`,
	"totalCostLastMonthLabel": `publicCloud_styles_totalCostLastMonthLabel__NvbrS`,
	"totalCostLastMonth": `publicCloud_styles_totalCostLastMonth__nGEeQ`,
	"totalCostLastMonthValue": `publicCloud_styles_totalCostLastMonthValue__80dTk`
};
export default ___CSS_LOADER_EXPORT___;
