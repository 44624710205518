import React, { useCallback, useMemo } from "react";
import { Table } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PaginationBox from "containers/PaginationBox";
import {
  setCostLastMonthCurrentPageAC,
  setCostLastMonthPerPageAC,
  setCostLastMonthSortFieldAC,
} from "store/dashboard/dashboard.actions";
import { dashboardSelectors } from "store/dashboard";
import { DetailedCost } from "types/dashboardApi.types";

import styles from "./styles.module.css";

interface CostLastMonthTableProps {
  currencySign: string;
}

const CostLastMonthTable = ({ currencySign }: CostLastMonthTableProps) => {
  const { t } = useTranslation("publicCloudDashboard");
  const dispatch = useDispatch();
  const { sortField, sortOrder, perPage, currentPage } = useSelector(dashboardSelectors.getCostLastMonth);
  const { list, pageCount } = useSelector(dashboardSelectors.getCostLastMonthTable);

  const columnsConfig = useMemo(
    () => [
      {
        key: "serviceFamily",
        dataIndex: "serviceFamily",
        title: t("costLastMonthTable.serviceFamily"),
      },
      {
        key: "serviceName",
        dataIndex: "serviceName",
        title: t("costLastMonthTable.serviceName"),
      },
      {
        key: "meter",
        dataIndex: "meter",
        title: t("costLastMonthTable.meter"),
      },
      {
        key: "subscriptionName",
        dataIndex: "subscriptionName",
        title: t("costLastMonthTable.subscriptionName"),
      },
      {
        key: "amount",
        dataIndex: "amount",
        title: t("costLastMonthTable.amount"),
        render: (amount: DetailedCost["amount"]) => `${currencySign}${amount}`,
      },
    ],
    [currencySign, t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setCostLastMonthSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setCostLastMonthPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setCostLastMonthCurrentPageAC(value)), [dispatch]);

  return (
    <div className={styles.costLastMonthTable}>
      <div className={styles.costLastMonthTableBlock}>
        <Table
          dataSource={list}
          columns={columnsConfig}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortFieldChange={handleSortFieldChange}
        />
      </div>
      <PaginationBox
        perPage={perPage}
        currentPage={currentPage}
        pageCount={pageCount}
        onPerPageChange={handlePerPageChange}
        onChangePage={handlePageChange}
      />
    </div>
  );
};

export default CostLastMonthTable;
