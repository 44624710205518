import React from "react";
import { Select } from "@skyportal/ui-kit";
import useFetchSubscriptionsData from "hooks/useFetchSubscriptionsData";
import { useDispatch, useSelector } from "react-redux";
import { dashboardSelectors } from "store/dashboard";
import { selectedSubscriptionIdsAC } from "store/dashboard/dashboard.actions";
import { ALL_SUBSCRIPTIONS_OPTION } from "store/dashboard/dashboard.reducer";
import CheckboxMUI from "@material-ui/core/Checkbox";

import styles from "./styles.module.css";

const SubscriptionsDropdown = () => {
  useFetchSubscriptionsData();
  const dispatch = useDispatch();
  const options = useSelector(dashboardSelectors.getSubscriptionDropdownOptions);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);

  const handleChange = (value: any) => {
    const selectedOptions = value.split(",");
    const isAllOptionSelected = selectedOptions.includes(ALL_SUBSCRIPTIONS_OPTION);
    const wasAllOptionSelected = selectedSubscriptionIds.includes(ALL_SUBSCRIPTIONS_OPTION);

    if (isAllOptionSelected && !wasAllOptionSelected) {
      return dispatch(selectedSubscriptionIdsAC(options.map((item) => item.value)));
    } else if (!isAllOptionSelected && wasAllOptionSelected) {
      return dispatch(selectedSubscriptionIdsAC([]));
    }

    if (selectedOptions.length === options.length - 1) {
      if (isAllOptionSelected) {
        return dispatch(
          selectedSubscriptionIdsAC(selectedOptions.filter((id: string) => id !== ALL_SUBSCRIPTIONS_OPTION))
        );
      } else {
        return dispatch(selectedSubscriptionIdsAC(options.map((item) => item.value)));
      }
    }
    return dispatch(selectedSubscriptionIdsAC(selectedOptions));
  };

  const showSelectedOptions = (selected: any) => {
    if (selected.includes(ALL_SUBSCRIPTIONS_OPTION)) {
      return ALL_SUBSCRIPTIONS_OPTION;
    }
    return selected.map((value: any) => options.find((option) => option.value === value)?.label).join(", ");
  };

  return (
    <Select
      data-testid="subscriptionsDropdown"
      className={styles.subscriptionsDropdown}
      MenuProps={{ getContentAnchorEl: null }}
      options={options}
      multiple
      value={selectedSubscriptionIds}
      onChange={handleChange}
      renderValue={showSelectedOptions}
      renderOption={(option) => (
        <div className={styles.subscriptionsDropdownOption}>
          <CheckboxMUI
            size="small"
            color="default"
            className={styles.publicCloudSkyPortalCheckbox}
            disableRipple
            checked={selectedSubscriptionIds.includes(option.value as string)}
          />
          {option.label}
        </div>
      )}
    />
  );
};

export default React.memo(SubscriptionsDropdown);
