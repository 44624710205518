import { UserProfile } from "@skyportal/auth-web-client";

export interface AppStateSlice {
  readonly userProfile: UserProfile;
}
export const SET_USER_PROFILE = "APP/SET_USER_PROFILE";
export type SetUserProfileAction = {
  type: typeof SET_USER_PROFILE;
  payload: UserProfile;
};
export type SetUserProfileAC = (userProfile: UserProfile) => SetUserProfileAction;

export type AppAction = SetUserProfileAction;
