import { useDispatch } from "react-redux";
import { ThunkAction } from "redux-thunk";
import configureStore from "./configureStore";
import rootReducer from "./rootReducer";

const initState: Partial<RootState> = {};

const store = configureStore(initState);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppAction = Parameters<AppDispatch>[0];
export type AppThunk<ReturnValue = Promise<any>> = ThunkAction<ReturnValue, RootState, null, AppAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useThunkDispatch = () => useDispatch<(...args: any[]) => AppThunk>();

export default store;
