// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicCloud_styles_costLastMonthTable__73gCf {
  position: relative;
  overflow: auto;
  padding-bottom: 16px;
}

.publicCloud_styles_costLastMonthTableBlock__zpiUE {
  padding: 0 16px 4px;
  overflow-x: auto;
}

.publicCloud_styles_costLastMonthTableBlock__zpiUE::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 3px;
}

.publicCloud_styles_costLastMonthTableBlock__zpiUE::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgb(0 0 0 / 80%);
}

.publicCloud_styles_costLastMonthTableBlock__zpiUE::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgb(0 0 0 / 10%);
}
`, "",{"version":3,"sources":["webpack://./src/components/CostLastMonthBlock/CostLastMonthTable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,kCAAkC;AACpC","sourcesContent":[".costLastMonthTable {\n  position: relative;\n  overflow: auto;\n  padding-bottom: 16px;\n}\n\n.costLastMonthTableBlock {\n  padding: 0 16px 4px;\n  overflow-x: auto;\n}\n\n.costLastMonthTableBlock::-webkit-scrollbar {\n  width: 3px;\n  height: 3px;\n  border-radius: 3px;\n}\n\n.costLastMonthTableBlock::-webkit-scrollbar-thumb {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 80%);\n}\n\n.costLastMonthTableBlock::-webkit-scrollbar-track {\n  border-radius: 3px;\n  background-color: rgb(0 0 0 / 10%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"costLastMonthTable": `publicCloud_styles_costLastMonthTable__73gCf`,
	"costLastMonthTableBlock": `publicCloud_styles_costLastMonthTableBlock__zpiUE`
};
export default ___CSS_LOADER_EXPORT___;
