import * as types from "./dashboard.types";

export const setSubscriptionsList: types.SetSubscriptionsListAC = (payload) => ({
  type: types.SET_SUBSCRIPTIONS_LIST,
  payload,
});

export const selectedSubscriptionIdsAC: types.SelectSubscriptionIdsAC = (payload) => ({
  type: types.SELECTED_SUBSCRIPTION_IDS,
  payload,
});

export const advisorScoreRequestAC: types.AdvisorScoreRequestAC = () => ({
  type: types.ADVISOR_SCORE_REQUEST,
});

export const advisorScoreRequestSuccessAC: types.AdvisorScoreRequestSuccessAC = (payload) => ({
  type: types.ADVISOR_SCORE_REQUEST_SUCCESS,
  payload,
});

export const advisorScoreRequestFailureAC: types.AdvisorScoreRequestFailureAC = () => ({
  type: types.ADVISOR_SCORE_REQUEST_FAILURE,
});

export const costLastMonthRequestAC: types.CostLastMonthRequestAC = () => ({
  type: types.COST_LAST_MONTH_REQUEST,
});

export const costLastMonthRequestSuccessAC: types.CostLastMonthRequestSuccessAC = (payload) => ({
  type: types.COST_LAST_MONTH_REQUEST_SUCCESS,
  payload,
});

export const costLastMonthRequestFailureAC: types.CostLastMonthRequestFailureAC = () => ({
  type: types.COST_LAST_MONTH_REQUEST_FAILURE,
});

export const setCostLastMonthCurrencyAC: types.SetCostLastMonthCurrencyAC = (payload) => ({
  type: types.SET_COST_LAST_MONTH_CURRENCY,
  payload,
});

export const setCostLastMonthSortFieldAC: types.SetCostLastMonthSortFieldAC = (payload) => ({
  type: types.SET_COST_LAST_MONTH_SORT_FIELD,
  payload,
});

export const setCostLastMonthPerPageAC: types.SetCostLastMonthPerPageAC = (payload) => ({
  type: types.SET_COST_LAST_MONTH_PER_PAGE,
  payload,
});

export const setCostLastMonthCurrentPageAC: types.SetCostLastMonthCurrentPageAC = (payload) => ({
  type: types.SET_COST_LAST_MONTH_CURRENT_PAGE,
  payload,
});
