import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as dashboard } from "./dashboard";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  contracts,
  dashboard,
  notification,
});

export default rootReducer;
