// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicCloud_styles_root__F4Z2C {
  margin-bottom: var(--padding-medium);
}

.publicCloud_styles_headerBlock__Ugjr- {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  gap: 16px;
}

.publicCloud_styles_publicCloudDropdownsBlock__MhRfu {
  display: flex;
  align-items: center;
  gap: 8px;
}

.publicCloud_styles_contractDropdown__NCrbG {
  min-width: 240px !important;
  padding-top: 7px !important;
  padding-left: 32px !important;
  margin: 8px 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/containers/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;EAC3B,6BAA6B;EAC7B,wBAAwB;AAC1B","sourcesContent":[".root {\n  margin-bottom: var(--padding-medium);\n}\n\n.headerBlock {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n\n.publicCloudDropdownsBlock {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n}\n\n.contractDropdown {\n  min-width: 240px !important;\n  padding-top: 7px !important;\n  padding-left: 32px !important;\n  margin: 8px 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `publicCloud_styles_root__F4Z2C`,
	"headerBlock": `publicCloud_styles_headerBlock__Ugjr-`,
	"publicCloudDropdownsBlock": `publicCloud_styles_publicCloudDropdownsBlock__MhRfu`,
	"contractDropdown": `publicCloud_styles_contractDropdown__NCrbG`
};
export default ___CSS_LOADER_EXPORT___;
