import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@skyportal/ui-kit";
import { notificationSelectors } from "store/notification";
import { useSnackbar } from "notistack";

import styles from "./styles.module.css";

const isError = (message: string) => message.toLowerCase().includes("error");

const composeMessage = (message: string) => message.replace("Error: ", "");

const Notification = () => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { message, description, id } = useSelector(notificationSelectors.getSlice);

  useEffect(() => {
    if (!message) {
      return;
    }

    enqueueSnackbar(composeMessage(message), {
      key: id,
      variant: isError(message) ? "error" : "info",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      autoHideDuration: isError(message) ? 5000 : 3000,
      onClick: () => closeSnackbar(id),
      content: (id) => (
        <div
          key={id}
          className={styles.notificationCard}
          style={{ backgroundColor: isError(message) ? "#ff5a5a" : "#4ef09d" }}
        >
          <div className={styles.notificationCardTitle}>
            <Icon
              icon={isError(message) ? "stop" : "info-success"}
              height={20}
              className={styles.notificationCardIcon}
            />
            {composeMessage(message)}
          </div>
          {description ? <div className={styles.notificationCardDescr}>{description}</div> : null}
        </div>
      ),
    });
  }, [message, id, description, enqueueSnackbar, closeSnackbar]);

  return null;
};

export default Notification;
