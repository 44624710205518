import { UserPermissionObjectInfo } from "@skyportal/auth-web-client";

export interface ContractModel extends UserPermissionObjectInfo {}

export type SelectedContractId = string;

export interface ContractsStateSlice {
  readonly list: ContractModel[];
  readonly selectedContractId: SelectedContractId;
}

export const SET_CONTRACTS_LIST = "SET_CONTRACTS_LIST";
export type SetContractsListAction = { type: typeof SET_CONTRACTS_LIST; payload: ContractModel[] };
export type SetContractsListAC = (payload: ContractModel[]) => SetContractsListAction;

export const SELECTED_CONTRACT_ID = "SELECTED_CONTRACT_ID";
export type SelectContractIdAction = { type: typeof SELECTED_CONTRACT_ID; payload: SelectedContractId };
export type SelectContractIdAC = (payload: SelectedContractId) => SelectContractIdAction;

export type ContractActions = SetContractsListAction | SelectContractIdAction;
