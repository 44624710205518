import { RequestStatus } from "types/common.types";
import * as types from "./dashboard.types";
import { ADVISOR_CATEGORY, CURRENCY_CODE } from "types/dashboardApi.types";

export const localStorageCurrencyKey = "privateCloudCurrency";
export const ALL_SUBSCRIPTIONS_OPTION = "All subscriptions";

const initState: types.DashboardStateSlice = {
  subscriptions: {
    list: [],
    selectedSubscriptionIds: [],
  },
  advisorScoreSummary: {
    totalScore: 0,
    costScore: 0,
    securityScore: 0,
    reliabilityScore: 0,
    operationalExcellenceScore: 0,
    performanceScore: 0,
    scoreHistory: [],
    requestStatus: RequestStatus.UNCALLED,
  },
  costLastMonth: {
    currency: (localStorage.getItem(localStorageCurrencyKey) as CURRENCY_CODE) || CURRENCY_CODE.EUR,
    totalCost: 0,
    detailedCost: [],
    requestStatus: RequestStatus.UNCALLED,
    sortField: "",
    sortOrder: "desc",
    currentPage: 1,
    perPage: 10,
  },
};

export default function dashboardReducer(
  state: types.DashboardStateSlice = initState,
  action: types.DashboardAction
): types.DashboardStateSlice {
  switch (action.type) {
    case types.SET_SUBSCRIPTIONS_LIST:
      return {
        ...state,
        subscriptions: {
          list: action.payload,
          selectedSubscriptionIds: [ALL_SUBSCRIPTIONS_OPTION, ...action.payload.map((sub) => sub.subscriptionId)],
        },
      };
    case types.SELECTED_SUBSCRIPTION_IDS:
      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          selectedSubscriptionIds: action.payload.filter(Boolean),
        },
      };
    case types.ADVISOR_SCORE_REQUEST: {
      return {
        ...state,
        advisorScoreSummary: { ...initState.advisorScoreSummary, requestStatus: RequestStatus.PENDING },
      };
    }
    case types.ADVISOR_SCORE_REQUEST_SUCCESS: {
      const categorizedScores = action.payload.scoresByCategory.reduce(
        (acc, cur) => {
          switch (cur.scoreType) {
            case ADVISOR_CATEGORY.Cost:
              acc.costScore = Number(cur.score.toFixed(2));
              break;
            case ADVISOR_CATEGORY.Security:
              acc.securityScore = Number(cur.score.toFixed(2));
              break;
            case ADVISOR_CATEGORY.Reliability:
              acc.reliabilityScore = Number(cur.score.toFixed(2));
              break;
            case ADVISOR_CATEGORY["Operational excellence"]:
              acc.operationalExcellenceScore = Number(cur.score.toFixed(2));
              break;
            case ADVISOR_CATEGORY.Performance:
              acc.performanceScore = Number(cur.score.toFixed(2));
              break;
          }

          return acc;
        },
        { costScore: 0, securityScore: 0, reliabilityScore: 0, operationalExcellenceScore: 0, performanceScore: 0 }
      );

      return {
        ...state,
        advisorScoreSummary: {
          totalScore: Number(action.payload.totalScore.toFixed(2)),
          ...categorizedScores,
          scoreHistory: action.payload.scoreHistory,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.ADVISOR_SCORE_REQUEST_FAILURE: {
      return {
        ...state,
        advisorScoreSummary: { ...initState.advisorScoreSummary, requestStatus: RequestStatus.FAILURE },
      };
    }
    case types.COST_LAST_MONTH_REQUEST: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          totalCost: initState.costLastMonth.totalCost,
          detailedCost: initState.costLastMonth.detailedCost,
          requestStatus: RequestStatus.PENDING,
        },
      };
    }
    case types.COST_LAST_MONTH_REQUEST_SUCCESS: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          totalCost: action.payload.totalCost,
          detailedCost: action.payload.detailedCost,
          requestStatus: RequestStatus.SUCCESS,
        },
      };
    }
    case types.COST_LAST_MONTH_REQUEST_FAILURE: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          requestStatus: RequestStatus.FAILURE,
        },
      };
    }
    case types.SET_COST_LAST_MONTH_CURRENCY: {
      return {
        ...state,
        costLastMonth: { ...state.costLastMonth, currency: action.payload },
      };
    }
    case types.SET_COST_LAST_MONTH_SORT_FIELD: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          sortField:
            state.costLastMonth.sortField === action.payload && state.costLastMonth.sortOrder === "asc"
              ? ""
              : action.payload,
          sortOrder:
            state.costLastMonth.sortField === action.payload && state.costLastMonth.sortOrder === "desc"
              ? "asc"
              : "desc",
          currentPage: 1,
        },
      };
    }
    case types.SET_COST_LAST_MONTH_PER_PAGE: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          perPage: action.payload,
          currentPage: 1,
        },
      };
    }
    case types.SET_COST_LAST_MONTH_CURRENT_PAGE: {
      return {
        ...state,
        costLastMonth: {
          ...state.costLastMonth,
          currentPage: action.payload,
        },
      };
    }

    default:
      return state;
  }
}
