// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicCloud_styles_subscriptionsDropdown__E4kL- {
  display: inline !important;
  width: 240px !important;
  border-color: var(--color-grey-03) !important;
  background-color: var(--color-white) !important;
}

.publicCloud_styles_subscriptionsDropdownOption__8BZa8 {
  display: flex;
  min-width: 238px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
}

.publicCloud_styles_publicCloudSkyPortalCheckbox__N-5TW {
  padding: 3px !important;
  border: 0 !important;
  color: var(--color-black) !important;
}
`, "",{"version":3,"sources":["webpack://./src/containers/SubscriptionsDropdown/styles.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,uBAAuB;EACvB,6CAA6C;EAC7C,+CAA+C;AACjD;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,oCAAoC;AACtC","sourcesContent":[".subscriptionsDropdown {\n  display: inline !important;\n  width: 240px !important;\n  border-color: var(--color-grey-03) !important;\n  background-color: var(--color-white) !important;\n}\n\n.subscriptionsDropdownOption {\n  display: flex;\n  min-width: 238px;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 0 8px;\n}\n\n.publicCloudSkyPortalCheckbox {\n  padding: 3px !important;\n  border: 0 !important;\n  color: var(--color-black) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subscriptionsDropdown": `publicCloud_styles_subscriptionsDropdown__E4kL-`,
	"subscriptionsDropdownOption": `publicCloud_styles_subscriptionsDropdownOption__8BZa8`,
	"publicCloudSkyPortalCheckbox": `publicCloud_styles_publicCloudSkyPortalCheckbox__N-5TW`
};
export default ___CSS_LOADER_EXPORT___;
