import { SET_CONTRACTS_LIST, SELECTED_CONTRACT_ID, SetContractsListAC, SelectContractIdAC } from "./contracts.types";

export const setContractsList: SetContractsListAC = (payload) => ({
  type: SET_CONTRACTS_LIST,
  payload,
});

export const selectedContractIdAC: SelectContractIdAC = (payload) => ({
  type: SELECTED_CONTRACT_ID,
  payload,
});
