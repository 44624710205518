import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { RootState } from "../index";
import { DashboardStateSlice } from "./dashboard.types";
import { DetailedCost } from "types/dashboardApi.types";
import { createSelector } from "reselect";
import { ALL_SUBSCRIPTIONS_OPTION } from "./dashboard.reducer";
import { sortSubscriptionsByName } from "utils/sort";

export const getSlice = (state: RootState): DashboardStateSlice => state.dashboard;

export const getSubscriptions = (state: RootState) => getSlice(state).subscriptions;
export const getSubscriptionDropdownOptions = createSelector(getSubscriptions, (subscriptions) => [
  { label: ALL_SUBSCRIPTIONS_OPTION, value: ALL_SUBSCRIPTIONS_OPTION },
  ...sortSubscriptionsByName(subscriptions.list).map((item) => ({
    label: item.subscriptionName,
    value: item.subscriptionId,
  })),
]);

export const getAdvisorScoreSummary = (state: RootState) => getSlice(state).advisorScoreSummary;
export const getCostLastMonth = (state: RootState) => getSlice(state).costLastMonth;

export const getCostScoreSummary = (state: RootState) => getSlice(state).advisorScoreSummary.costScore;
export const getSecurityScoreSummary = (state: RootState) => getSlice(state).advisorScoreSummary.securityScore;
export const getReliabilityScoreSummary = (state: RootState) => getSlice(state).advisorScoreSummary.reliabilityScore;
export const getOperationalExcellenceScoreSummary = (state: RootState) =>
  getSlice(state).advisorScoreSummary.operationalExcellenceScore;
export const getPerformanceScoreSummary = (state: RootState) => getSlice(state).advisorScoreSummary.performanceScore;
export const getAdvisorScoreRequestStatus = (state: RootState) => getSlice(state).advisorScoreSummary.requestStatus;

const makeServiceFamilySortNormalizer = (item: DetailedCost) => item.serviceFamily;
const makeServiceNameSortNormalizer = (item: DetailedCost) => item.serviceName;
const makeMeterSortNormalizer = (item: DetailedCost) => item.meter;
const makeSubscriptionNameSortNormalizer = (item: DetailedCost) => item.subscriptionName;
const makeAmountSortNormalizer = (item: DetailedCost) => item.amount;

const costLastMonthComposer = ({
  detailedCost,
  sortField,
  sortOrder,
  currentPage,
  perPage,
}: DashboardStateSlice["costLastMonth"]) => {
  let _list: DetailedCost[] = [...detailedCost];

  const sortHandler: any = {
    serviceFamily: makeUniversalSort(makeServiceFamilySortNormalizer),
    serviceName: makeUniversalSort(makeServiceNameSortNormalizer),
    meter: makeUniversalSort(makeMeterSortNormalizer),
    subscriptionName: makeUniversalSort(makeSubscriptionNameSortNormalizer),
    amount: makeUniversalSort(makeAmountSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getCostLastMonthTable = (state: RootState) => {
  const costLastMonthSlice = getCostLastMonth(state);
  return costLastMonthComposer(costLastMonthSlice);
};
