import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { Spinner } from "@skyportal/ui-kit";
import { InfoBoxesCardsConfig } from "./constants";
import { InfoBoxesCardConfigType, InfoBoxesCardType } from "./types";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import ProgressBar from "containers/ProgressBar";
import { dashboardSelectors } from "store/dashboard";

import styles from "./styles.module.css";

interface InfoBoxesCardProps {
  variant: InfoBoxesCardType;
}

const InfoBoxesCard: React.FC<InfoBoxesCardProps> = ({ variant }) => {
  const { t } = useTranslation("publicCloudDashboard");
  const cardScore = useSelector(InfoBoxesCardsConfig[variant].selector);
  const requestStatus = useSelector(dashboardSelectors.getAdvisorScoreRequestStatus);
  const cardThunk = InfoBoxesCardsConfig[variant].thunk;
  const cardConfig: InfoBoxesCardConfigType = InfoBoxesCardsConfig[variant];
  const wrapperClasses = cn(styles.infoBoxRoot, styles[cardConfig.style]);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);
  const reloadData = useCallback(() => {
    if (selectedContractId && selectedSubscriptionIds.length) {
      thunkDispatch(cardThunk(selectedContractId, selectedSubscriptionIds));
    }
  }, [cardThunk, selectedContractId, selectedSubscriptionIds, thunkDispatch]);

  return (
    <div className={wrapperClasses}>
      {(requestStatus === RequestStatus.UNCALLED || requestStatus === RequestStatus.PENDING) && (
        <Spinner data-testid="infoBoxSpinner" show />
      )}
      {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
      <div className={styles.infoBox}>
        <div className={styles.infoBoxLabel}>{t(cardConfig.labelPhrase)}</div>
        <div className={styles.subLabel}>{cardScore}%</div>
        <ProgressBar completed={cardScore} total={100} fillColor="#fff" bgColor={cardConfig.progressBarBgColor} />
      </div>
    </div>
  );
};

export default InfoBoxesCard;
