import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = (): BreadcrumbsConfigItem[] => {
  const publicCloud: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:publicCloud"),
    path: "/public-cloud/overview",
  };

  const startPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:start"),
    path: "/start",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: publicCloud.label,
      crumbs: [startPage],
      match: {
        path: publicCloud.path,
        exact: true,
      },
    },
  ];

  return breadcrumbsConfig;
};
