import React, { useMemo } from "react";
import { Dropdown } from "@skyportal/ui-kit";
import { PAGINATION_STEPS } from "utils/table";

export interface PaginationPerSizeSelectProps {
  onChange: (value: number) => void;
  value: number;
  steps?: number[];
}

const PaginationPerSizeSelect = ({ onChange, value, steps = PAGINATION_STEPS }: PaginationPerSizeSelectProps) => {
  const handlePerPageCountChange = (value: string) => onChange(Number(value));
  const options = useMemo(() => steps.map((step) => ({ value: String(step), label: String(step) })), [steps]);

  return <Dropdown data-testid="perPageDropdown" value={value} onChange={handlePerPageCountChange} options={options} />;
};

export default PaginationPerSizeSelect;
