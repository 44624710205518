import { useEffect } from "react";
import { useThunkDispatch } from "store";
import { appSelectors, appThunks } from "store/app";
import { useSelector } from "react-redux";

const useFetchUser = () => {
  const thunkDispatch = useThunkDispatch();
  const user = useSelector(appSelectors.getUserProfile);

  useEffect(() => {
    thunkDispatch(appThunks.getUserInfo());
  }, [thunkDispatch]);

  return user;
};

export default useFetchUser;
