import { AdvisorScoreHistory } from "types/dashboardApi.types";

export const composeChartOptions = (scoreHistory: AdvisorScoreHistory[]) => {
  scoreHistory.sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacing: [16, 16, 12, 16],
      plotShadow: false,
      type: "line",
      height: 147,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      split: true,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    yAxis: [
      {
        title: null,
        min: 0,
        max: 100,
        minRange: 25,
        labels: {
          format: "{text}%",
        },
        gridLineDashStyle: "Dash",
      },
    ],
    xAxis: {
      title: null,
      type: "datetime",
      gridLineWidth: 0,
    },
    plotOptions: {
      line: {
        animation: {
          duration: 1000,
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Score",
        data: scoreHistory.map((item) => {
          const timestamp = Number(new Date(item.date));
          return { x: timestamp, y: item.score };
        }),
        color: "#c48df7",
        lineWidth: 2,
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y}%</b><br>`,
        },
      },
    ],
    legend: {
      enabled: false,
    },
  };
};
