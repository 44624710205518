import { AppThunk } from "store";
import { authClient } from "api/clients";
import * as appActions from "./app.actions";
import { UserPermissionObjectInfo } from "@skyportal/auth-web-client/dist";
import { sortCustomersByParentName } from "utils/sort";
import { contractActions } from "store/contracts";
import config from "config";

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();

    const pcContracts = sortCustomersByParentName(
      userProfile.permissions.entity
        .filter((contract) => contract.entityType === "PC")
        .map((contract) => {
          const parentObject = userProfile.permissions[contract.parentType as string]?.find(
            (item) => item.id === contract.parentId
          );
          const parentName = parentObject?.name ?? "Noname";
          return { ...contract, parentName } as UserPermissionObjectInfo;
        })
    );

    if (!pcContracts.length) {
      window.location.href = config.baseUrl;
    }

    dispatch(appActions.setUserProfile(userProfile));
    dispatch(contractActions.setContractsList(pcContracts));
  } catch (error) {
    console.error(error, "USER INFO REQUEST");
  }
};
