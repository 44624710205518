import cn from "classnames";
import React, { useMemo } from "react";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  completed: number;
  total: number;
  fillColor: string;
  bgColor: string;
}

const ProgressBar: React.FC<PageHeaderProps> = ({ completed, total, fillColor, bgColor }) => {
  const isCompletedEmptyOrFull = useMemo(() => completed === 0 || completed / total === 1, [completed, total]);
  const containerStyles = useMemo(() => ({ backgroundColor: bgColor }), [bgColor]);

  const fillStyles = useMemo(
    () => ({
      width: `${(completed / total) * 100}%`,
      backgroundColor: fillColor,
    }),
    [completed, fillColor, total]
  );

  return (
    <div className={styles.progressBarRoot}>
      <div className={styles.progressBarContainer} style={containerStyles}>
        <div
          style={fillStyles}
          className={cn(styles.progressBarCompleted, {
            [styles.progressBarEmptyOrFullCompleted]: isCompletedEmptyOrFull,
          })}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
