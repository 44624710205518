import { UserPermissionObjectInfo } from "@skyportal/auth-web-client/dist";
import { SubcriptionModel } from "types/dashboardApi.types";

export const sortCustomersByParentName = (list: UserPermissionObjectInfo[]): UserPermissionObjectInfo[] =>
  [...list].sort((a, b) => ((a.parentName as string).toLowerCase() >= (b.parentName as string).toLowerCase() ? 1 : -1));

export const sortSubscriptionsByName = (list: SubcriptionModel[]): SubcriptionModel[] =>
  [...list].sort((a, b) =>
    (a.subscriptionName as string).toLowerCase() >= (b.subscriptionName as string).toLowerCase() ? 1 : -1
  );
