import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";

const useFetchCostLastMonthData = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);
  const { currency } = useSelector(dashboardSelectors.getCostLastMonth);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedContractId && selectedSubscriptionIds.length) {
      thunkDispatch(
        dashboardThunks.getCostLastMonthInfo(selectedContractId, selectedSubscriptionIds, currency, controller.signal)
      );
    }

    return () => controller.abort();
  }, [thunkDispatch, selectedContractId, selectedSubscriptionIds, currency]);

  return useSelector(dashboardSelectors.getCostLastMonth);
};

export default useFetchCostLastMonthData;
