export const ERROR_CANCELED_CODE = "ERR_CANCELED";

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};
