import { ALL_SUBSCRIPTIONS_OPTION } from "store/dashboard/dashboard.reducer";
import { publicCloudApiClient } from "./clients";
import {
  SubscriptionsResponse,
  CostLastMonthResponse,
  AdvisorScoreResponse,
  CURRENCY_CODE,
} from "types/dashboardApi.types";

export const getSubscriptions = (contractId: string, signal?: AbortSignal) =>
  publicCloudApiClient.request<SubscriptionsResponse>({
    method: "GET",
    url: `/v1/contracts/${contractId}/public-cloud/subscriptions`,
    signal,
  });

export const getAdvisorScore = (contractId: string, subscriptionIds: string[], signal?: AbortSignal) =>
  publicCloudApiClient.request<AdvisorScoreResponse>({
    method: "POST",
    url: `/v1/contracts/${contractId}/public-cloud/advisor-score`,
    data: { subscriptions: subscriptionIds.filter((item) => item !== ALL_SUBSCRIPTIONS_OPTION) },
    signal,
  });

export const getCostLastMonth = (
  contractId: string,
  subscriptionIds: string[],
  currencyCode: CURRENCY_CODE,
  signal?: AbortSignal
) =>
  publicCloudApiClient.request<CostLastMonthResponse>({
    method: "POST",
    url: `/v1/contracts/${contractId}/public-cloud/cost-last-month`,
    data: {
      subscriptions: subscriptionIds.filter((item) => item !== ALL_SUBSCRIPTIONS_OPTION),
      currency: currencyCode,
    },
    signal,
  });
