export const formatDate = (inputDate: string | number | Date): string => {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
};

export const formatAMPM = (inputDate: string | number | Date) => {
  const date = new Date(inputDate);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours %= 12;
  hours = hours !== 0 ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${String(minutes).padStart(2, "0")} ${ampm}`;
};

export const getPreviousMonth = (): string => {
  const today = new Date();

  today.setMonth(today.getMonth() - 1);

  const previousMonth = today.toLocaleString("en-US", {
    month: "long",
  });

  const previousYear = today.getFullYear();

  return `${previousMonth} ${previousYear}`;
};
