export enum CURRENCY_CODE {
  EUR = "EUR",
  DKK = "DKK",
  NOK = "NOK",
  SEK = "SEK",
}

export enum ADVISOR_CATEGORY {
  Cost = "Cost",
  Security = "Security",
  Reliability = "Reliability",
  "Operational excellence" = "Operational excellence",
  Performance = "Performance",
}

export interface SubcriptionModel {
  subscriptionName: string;
  subscriptionId: string;
}

export interface SubscriptionsResponse {
  subscriptions: SubcriptionModel[];
}

export interface AdvisorCategoryScore {
  scoreType: ADVISOR_CATEGORY;
  score: number;
}

export interface AdvisorScoreHistory {
  date: string;
  score: number;
}

export interface AdvisorScoreResponse {
  totalScore: number;
  scoresByCategory: AdvisorCategoryScore[];
  scoreHistory: AdvisorScoreHistory[];
}

export interface DetailedCost {
  amount: number;
  meter: string;
  serviceFamily: string;
  serviceName: string;
  subscriptionName: string;
}

export interface CostLastMonthResponse {
  totalCost: number;
  detailedCost: DetailedCost[];
}
