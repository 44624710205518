import { BreadcrumbsConfigItem } from "./config";

export const makeTitleFromBreadcrumbs = (config: BreadcrumbsConfigItem): string =>
  [
    config.label,
    ...(config.crumbs || [])
      .filter((item) => item.label !== "Start")
      .map((item) => item.label)
      .reverse(),
    "Skyportal",
  ].join(" - ");
