import * as types from "./notification.types";

export const notificationAC: types.NotificationAC = (payload) => ({
  type: types.NOTIFICATION,
  payload: {
    message: payload.message,
    description: null,
  },
});

export const notificationErrorAC: types.NotificationAC = (payload) => ({
  type: types.NOTIFICATION,
  payload: {
    message: `Error: ${payload.message}`,
    description: payload.description || null,
  },
});
