import React from "react";
import ReactDOM from "react-dom";
import { RouterProps } from "react-router-dom";
import browserHistory from "libs/history";
import App from "./App";
import "./index.css";

interface AppProps {
  history: RouterProps["history"];
}

if (process.env.REACT_APP_IS_MF) {
  window.renderPublicCloud = (containerId: string, history: AppProps["history"]) => {
    ReactDOM.render(<App history={history} />, document.getElementById(containerId));
  };

  window.unmountPublicCloud = (containerId: string) => {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
  };
} else {
  ReactDOM.render(<App history={browserHistory} />, document.getElementById("root"));
}
