import * as React from "react";
import cn from "classnames";
import { Pagination } from "@skyportal/ui-kit";
import PaginationPerSizeSelect from "./PaginationPerSizeSelect";

import styles from "./styles.module.css";

export interface PaginationBoxProps {
  onPerPageChange: (value: number) => void;
  perPage: number;
  currentPage: number;
  pageCount: number;
  onChangePage: (page: number) => void;
  steps?: number[];
  className?: string;
}

const PaginationBox = ({
  onPerPageChange,
  perPage,
  currentPage,
  pageCount,
  onChangePage,
  steps,
  className,
  ...rest
}: PaginationBoxProps) => {
  const handlePageChange = (page: number) => onChangePage(page);

  return (
    <div className={cn(styles.boxRoot, className)} data-testid="paginationBox" {...rest}>
      <PaginationPerSizeSelect steps={steps} value={perPage} onChange={onPerPageChange} />
      <Pagination
        className={styles.pagination}
        page={currentPage}
        count={pageCount}
        onChange={handlePageChange}
        variant="outlined"
        shape="rounded"
      />
    </div>
  );
};

export default PaginationBox;
