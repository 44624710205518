import React from "react";
import ContractDropdown from "containers/ContractDropdown";

import styles from "./styles.module.css";
import SubscriptionsDropdown from "containers/SubscriptionsDropdown";
import ModuleBreadcrumbs from "containers/ModuleBreadcrumbs";
import { Title } from "@skyportal/ui-kit";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <header className={styles.root}>
      <div className={styles.headerBlock}>
        <ModuleBreadcrumbs />
        <Title level={2}>{title}</Title>
      </div>
      <div className={styles.publicCloudDropdownsBlock}>
        <ContractDropdown className={styles.contractDropdown} />
        <SubscriptionsDropdown />
      </div>
    </header>
  );
};

export default PageHeader;
