import React from "react";
import { SplashScreen } from "@skyportal/ui-kit";
import useFetchUser from "../../hooks/useFetchUser";

const SpinnerRenderer: React.FC = ({ children }) => {
  const user = useFetchUser();

  if (!user.email) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default SpinnerRenderer;
