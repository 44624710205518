import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";

const useFetchAdvisorScoreData = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedContractId && selectedSubscriptionIds.length) {
      thunkDispatch(
        dashboardThunks.getAdvisorScoreInfo(selectedContractId, selectedSubscriptionIds, controller.signal)
      );
    }

    return () => controller.abort();
  }, [thunkDispatch, selectedContractId, selectedSubscriptionIds]);

  return useSelector(dashboardSelectors.getAdvisorScoreSummary);
};

export default useFetchAdvisorScoreData;
