import React from "react";
import { Icon } from "@skyportal/ui-kit";

import styles from "./styles.module.css";

export interface CardFallbackProps {
  onReload: () => void;
}

const CardFallback: React.FC<CardFallbackProps> = ({ onReload }) => {
  return (
    <div className={styles.fallbackBlock} data-testid="cardFallback">
      <div className={styles.fallbackBlockGreying}></div>

      <div className={styles.fallbackReloadBtnWrapper} onClick={onReload} data-testid="cardFallbackReloadBtn">
        <Icon icon="circle" className={styles.fallbackReloadBtn} />
      </div>
    </div>
  );
};

export default React.memo(CardFallback);
