import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";

const useFetchSubscriptionsData = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedContractId) {
      thunkDispatch(dashboardThunks.getSubscriptionsInfo(selectedContractId, controller.signal));
    }

    return () => controller.abort();
  }, [thunkDispatch, selectedContractId]);

  return useSelector(dashboardSelectors.getSubscriptions);
};

export default useFetchSubscriptionsData;
