import { RequestStatus } from "types/common.types";
import {
  AdvisorScoreHistory,
  AdvisorScoreResponse,
  CURRENCY_CODE,
  CostLastMonthResponse,
  DetailedCost,
  SubcriptionModel,
} from "types/dashboardApi.types";

export type SortFieldType = keyof DetailedCost | "";
export type SortOrderType = "asc" | "desc";

export interface WithRequestStatus {
  requestStatus: RequestStatus;
}

export interface SubscriptionsSlice {
  list: SubcriptionModel[];
  selectedSubscriptionIds: string[];
}

export interface AdvisorScoreSlice {
  totalScore: number;
  costScore: number;
  securityScore: number;
  reliabilityScore: number;
  operationalExcellenceScore: number;
  performanceScore: number;
  scoreHistory: AdvisorScoreHistory[];
}

export interface CostLastMonthSlice {
  currency: CURRENCY_CODE;
  totalCost: number;
  detailedCost: DetailedCost[];
  sortField: SortFieldType;
  sortOrder: SortOrderType;
  currentPage: number;
  perPage: number;
}

export interface DashboardStateSlice {
  readonly subscriptions: SubscriptionsSlice;
  readonly advisorScoreSummary: AdvisorScoreSlice & WithRequestStatus;
  readonly costLastMonth: CostLastMonthSlice & WithRequestStatus;
}
export const SET_SUBSCRIPTIONS_LIST = "SET_SUBSCRIPTIONS_LIST";
export type SetSubscriptionsListAction = { type: typeof SET_SUBSCRIPTIONS_LIST; payload: SubcriptionModel[] };
export type SetSubscriptionsListAC = (payload: SubcriptionModel[]) => SetSubscriptionsListAction;

export const SELECTED_SUBSCRIPTION_IDS = "SELECTED_SUBSCRIPTION_IDS";
export type SelectSubscriptionIdsAction = { type: typeof SELECTED_SUBSCRIPTION_IDS; payload: string[] };
export type SelectSubscriptionIdsAC = (payload: string[]) => SelectSubscriptionIdsAction;

export const ADVISOR_SCORE_REQUEST = "ADVISOR_SCORE_REQUEST";
export type AdvisorScoreRequestAction = {
  type: typeof ADVISOR_SCORE_REQUEST;
};
export type AdvisorScoreRequestAC = () => AdvisorScoreRequestAction;

export const ADVISOR_SCORE_REQUEST_SUCCESS = "ADVISOR_SCORE_REQUEST_SUCCESS";
export type AdvisorScoreRequestSuccessAction = {
  type: typeof ADVISOR_SCORE_REQUEST_SUCCESS;
  payload: AdvisorScoreResponse;
};
export type AdvisorScoreRequestSuccessAC = (payload: AdvisorScoreResponse) => AdvisorScoreRequestSuccessAction;

export const ADVISOR_SCORE_REQUEST_FAILURE = "ADVISOR_SCORE_REQUEST_FAILURE";
export type AdvisorScoreRequestFailureAction = {
  type: typeof ADVISOR_SCORE_REQUEST_FAILURE;
};
export type AdvisorScoreRequestFailureAC = () => AdvisorScoreRequestFailureAction;

export const COST_LAST_MONTH_REQUEST = "COST_LAST_MONTH_REQUEST";
export type CostLastMonthRequestAction = {
  type: typeof COST_LAST_MONTH_REQUEST;
};
export type CostLastMonthRequestAC = () => CostLastMonthRequestAction;

export const COST_LAST_MONTH_REQUEST_SUCCESS = "COST_LAST_MONTH_REQUEST_SUCCESS";
export type CostLastMonthRequestSuccessAction = {
  type: typeof COST_LAST_MONTH_REQUEST_SUCCESS;
  payload: CostLastMonthResponse;
};
export type CostLastMonthRequestSuccessAC = (payload: CostLastMonthResponse) => CostLastMonthRequestSuccessAction;

export const COST_LAST_MONTH_REQUEST_FAILURE = "COST_LAST_MONTH_REQUEST_FAILURE";
export type CostLastMonthRequestFailureAction = {
  type: typeof COST_LAST_MONTH_REQUEST_FAILURE;
};
export type CostLastMonthRequestFailureAC = () => CostLastMonthRequestFailureAction;

export const SET_COST_LAST_MONTH_CURRENCY = "SET_COST_LAST_MONTH_CURRENCY";
export type SetCostLastMonthCurrencyAction = {
  type: typeof SET_COST_LAST_MONTH_CURRENCY;
  payload: CURRENCY_CODE;
};
export type SetCostLastMonthCurrencyAC = (payload: CURRENCY_CODE) => SetCostLastMonthCurrencyAction;

export const SET_COST_LAST_MONTH_SORT_FIELD = "SET_COST_LAST_MONTH_SORT_FIELD";
export type SetCostLastMonthSortFieldAction = {
  type: typeof SET_COST_LAST_MONTH_SORT_FIELD;
  payload: SortFieldType;
};
export type SetCostLastMonthSortFieldAC = (payload: SortFieldType) => SetCostLastMonthSortFieldAction;

export const SET_COST_LAST_MONTH_PER_PAGE = "SET_COST_LAST_MONTH_PER_PAGE";
export type SetCostLastMonthPerPageAction = {
  type: typeof SET_COST_LAST_MONTH_PER_PAGE;
  payload: number;
};
export type SetCostLastMonthPerPageAC = (payload: number) => SetCostLastMonthPerPageAction;

export const SET_COST_LAST_MONTH_CURRENT_PAGE = "SET_COST_LAST_MONTH_CURRENT_PAGE";
export type SetCostLastMonthCurrentPageAction = {
  type: typeof SET_COST_LAST_MONTH_CURRENT_PAGE;
  payload: number;
};
export type SetCostLastMonthCurrentPageAC = (payload: number) => SetCostLastMonthCurrentPageAction;

export type DashboardAction =
  | SetSubscriptionsListAction
  | SelectSubscriptionIdsAction
  | AdvisorScoreRequestAction
  | AdvisorScoreRequestSuccessAction
  | AdvisorScoreRequestFailureAction
  | CostLastMonthRequestAction
  | CostLastMonthRequestSuccessAction
  | CostLastMonthRequestFailureAction
  | SetCostLastMonthCurrencyAction
  | SetCostLastMonthSortFieldAction
  | SetCostLastMonthPerPageAction
  | SetCostLastMonthCurrentPageAction;
