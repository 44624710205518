import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader";
import InfoBoxCards from "components/InfoBoxCards";
import AdvisorScoreBlock from "components/AdvisorScoreBlock";
import CostLastMonthBlock from "components/CostLastMonthBlock";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation("publicCloudDashboard");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <InfoBoxCards />
      <AdvisorScoreBlock />
      <CostLastMonthBlock />
    </PageLayout>
  );
};

export default Dashboard;
