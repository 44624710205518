import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

const configureStore = (initialState: {} = {}) => {
  const composeEnhancers =
    // eslint-disable-next-line
    (process.env.NODE_ENV === "development" && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(thunk)));
};

export default configureStore;
