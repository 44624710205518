export enum RequestStatus {
  UNCALLED = "UNCALLED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
}

export type SortOrder = "asc" | "desc";

export type ApiErrorCode = number | null;
