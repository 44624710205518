// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publicCloud_styles_progressBarRoot__fkZr3 {
  display: flex;
  align-items: center;
}

.publicCloud_styles_progressBarContainer__oslRx {
  width: 100%;
  min-width: 70px;
  height: 5px;
  border-radius: 4px;
  margin-right: 8px;
}

.publicCloud_styles_progressBarCompleted__1fydi {
  height: 100%;
  border-radius: 4px;
  transition: width 0.5s;
}

.publicCloud_styles_progressBarEmptyOrFullCompleted__1yw8n {
  border-right: none;
  border-bottom-right-radius: inherit;
  border-top-right-radius: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/containers/ProgressBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,WAAW;EACX,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,gCAAgC;AAClC","sourcesContent":[".progressBarRoot {\n  display: flex;\n  align-items: center;\n}\n\n.progressBarContainer {\n  width: 100%;\n  min-width: 70px;\n  height: 5px;\n  border-radius: 4px;\n  margin-right: 8px;\n}\n\n.progressBarCompleted {\n  height: 100%;\n  border-radius: 4px;\n  transition: width 0.5s;\n}\n\n.progressBarEmptyOrFullCompleted {\n  border-right: none;\n  border-bottom-right-radius: inherit;\n  border-top-right-radius: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarRoot": `publicCloud_styles_progressBarRoot__fkZr3`,
	"progressBarContainer": `publicCloud_styles_progressBarContainer__oslRx`,
	"progressBarCompleted": `publicCloud_styles_progressBarCompleted__1fydi`,
	"progressBarEmptyOrFullCompleted": `publicCloud_styles_progressBarEmptyOrFullCompleted__1yw8n`
};
export default ___CSS_LOADER_EXPORT___;
