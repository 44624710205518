import { ContractActions, ContractsStateSlice } from "./contracts.types";

const initState: ContractsStateSlice = {
  list: [],
  selectedContractId: "",
};

export default function contractReducer(
  state: ContractsStateSlice = initState,
  action: ContractActions
): ContractsStateSlice {
  switch (action.type) {
    case "SET_CONTRACTS_LIST":
      return {
        ...state,
        list: action.payload,
        selectedContractId: action.payload[0].name as string,
      };
    case "SELECTED_CONTRACT_ID":
      return {
        ...state,
        selectedContractId: action.payload,
      };
    default:
      return state;
  }
}
