import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon, Spinner } from "@skyportal/ui-kit";
import { RequestStatus } from "types/common.types";
import CardFallback from "containers/CardFallback";
import { contractSelectors } from "store/contracts";
import { useThunkDispatch } from "store";
import ProgressBar from "containers/ProgressBar";
import { dashboardSelectors, dashboardThunks } from "store/dashboard";
import ScoreHistoryChart from "./ScoreHistoryChart";
import useFetchAdvisorScoreData from "hooks/useFetchAdvisorScoreData";

import styles from "./styles.module.css";

const AdvisorScoreBlock: React.FC = () => {
  useFetchAdvisorScoreData();
  const { t } = useTranslation("publicCloudDashboard");
  const { totalScore: advisorScore, requestStatus } = useSelector(dashboardSelectors.getAdvisorScoreSummary);

  const thunkDispatch = useThunkDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);
  const { selectedSubscriptionIds } = useSelector(dashboardSelectors.getSubscriptions);
  const reloadData = useCallback(() => {
    if (selectedContractId && selectedSubscriptionIds.length) {
      thunkDispatch(dashboardThunks.getAdvisorScoreInfo(selectedContractId, selectedSubscriptionIds));
    }
  }, [selectedContractId, selectedSubscriptionIds, thunkDispatch]);

  return (
    <div className={styles.advisorScoreBlock}>
      <div className={styles.infoBoxRoot} data-testid="advisorScoreInfoBox">
        {(requestStatus === RequestStatus.UNCALLED || requestStatus === RequestStatus.PENDING) && (
          <Spinner data-testid="infoBoxSpinner" show />
        )}
        {requestStatus === RequestStatus.FAILURE && <CardFallback onReload={reloadData} />}
        <div className={styles.infoBox}>
          <div className={styles.infoBoxBgIcon}>
            <Icon icon="awards" color="popPurple" height={56} />
          </div>
          <div className={styles.infoBoxLabel}>{t("infoBoxesBlock.advisorScoreLabel")}</div>
          <div className={styles.subLabel}>{advisorScore}%</div>
          <ProgressBar completed={advisorScore} total={100} fillColor="#fff" bgColor="#c48df7" />
        </div>
      </div>
      <ScoreHistoryChart />
    </div>
  );
};

export default AdvisorScoreBlock;
